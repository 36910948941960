import { v4 as uuidv4 } from "uuid"
import m1 from "../../../static/musics/01.mp3"
import m2 from "../../../static/musics/02.mp3"
import m3 from "../../../static/musics/03.mp3"
import m4 from "../../../static/musics/04.mp3"
import m5 from "../../../static/musics/05.mp3"
import m6 from "../../../static/musics/06.mp3"
import m7 from "../../../static/musics/07.mp3"
import m8 from "../../../static/musics/08.mp3"
import m9 from "../../../static/musics/09.mp3"
import m10 from "../../../static/musics/10.mp3"
import m11 from "../../../static/musics/11.mp3"
import m12 from "../../../static/musics/12.mp3"
import m13 from "../../../static/musics/13.mp3"
import m14 from "../../../static/musics/14.mp3"
import m15 from "../../../static/musics/15.mp3"
import m16 from "../../../static/musics/16.mp3"
import m17 from "../../../static/musics/17.mp3"
import m18 from "../../../static/musics/18.mp3"
import m19 from "../../../static/musics/19.mp3"

function chillHop() {
  return [
    {
      name: "1",
      cover: "m1",
      artist: "Artist",
      audio: m1,
      color: ["#205950", "#2ab3bf"],
      id: uuidv4(),
      active: true,
    },
    {
      name: "2",
      cover: "m2",
      artist: "Artist",
      audio: m2,
      color: ["#668EA9", "#ab417f"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "3",
      cover: "m3",
      artist: "Artist",
      audio: m3,
      color: ["#CD221D", "#c94043"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "4",
      cover: "m4",
      artist: "Artist",
      audio: m4,
      color: ["#118EA9", "#ab417f"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "5",
      cover: "m5",
      artist: "Artist",
      audio: m5,
      color: ["#226011", "#c94043"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "6",
      cover: "404",
      artist: "Artist",
      audio: m6,
      color: ["#205950", "#2ab3bf"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "7",
      cover: "m1",
      artist: "Artist",
      audio: m7,
      color: ["#668EA9", "#ab417f"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "8",
      cover: "m2",
      artist: "Artist",
      audio: m8,
      color: ["#CD221D", "#c94043"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "9",
      cover: "m3",
      artist: "Artist",
      audio: m9,
      color: ["#118EA9", "#ab417f"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "10",
      cover: "m1",
      artist: "Artist",
      audio: m10,
      color: ["#226011", "#c94043"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "11",
      cover: "m1",
      artist: "Artist",
      audio: m11,
      color: ["#205950", "#2ab3bf"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "12",
      cover: "m4",
      artist: "Artist",
      audio: m12,
      color: ["#668EA9", "#ab417f"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "13",
      cover: "m5",
      artist: "Artist",
      audio: m13,
      color: ["#CD221D", "#c94043"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "14",
      cover: "m4",
      artist: "Artist",
      audio: m14,
      color: ["#118EA9", "#ab417f"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "15",
      cover: "m5",
      artist: "Artist",
      audio: m15,
      color: ["#226011", "#c94043"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "16",
      cover: "m2",
      artist: "Artist",
      audio: m16,
      color: ["#205950", "#2ab3bf"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "17",
      cover: "m3",
      artist: "Artist",
      audio: m17,
      color: ["#668EA9", "#ab417f"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "18",
      cover: "m3",
      artist: "Artist",
      audio: m18,
      color: ["#CD221D", "#c94043"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "19",
      cover: "m4",
      artist: "Artist",
      audio: m19,
      color: ["#118EA9", "#ab417f"],
      id: uuidv4(),
      active: false,
    },
  ]
}

export default chillHop
